/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    br: "br",
    h2: "h2",
    div: "div",
    ul: "ul",
    li: "li",
    strong: "strong",
    img: "img"
  }, _provideComponents(), props.components), {TableOfContents, SideBySide, StateCta, LandingPageCta, ThreeColCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!StateCta) _missingMdxReference("StateCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ix-hearing-aids-box-black.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Engineered for active people who desire world-class hearing that lasts all day, Horizon Go IX rises to the top of its class with an extended battery life that only stops when you do. With seamless connectivity and no tiny batteries to change, staying connected while on the go has never been so easy. The entire line of ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/",
    className: "c-md-a"
  }, "Horizon IX devices"), " was born out of the need to take hearing performance to the next level.\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "Built with the intention of keeping people more active and feeling younger for longer, Horizon IX offers incredible speech clarity and a sense of freedom that other ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aid"), " brands have had difficulty achieving. All hear.com's Horizon IX hearing aids, including the Horizon Go IX, are designed to be sleeker and more discreet with incredible power to help you hear naturally and more clearly in any environment.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "horizon-go-ix-dual-processor",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#horizon-go-ix-dual-processor",
    "aria-label": "horizon go ix dual processor permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Horizon Go IX dual processor"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The use of two separate processors allows for dedicated processing of speech and noise, improving the overall listening experience. By emphasizing speech and reducing noise, these systems enable better understanding of spoken words in challenging environments, such as crowded places, noisy offices, or outdoor settings."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It's important to note that the exact implementation of this technology can vary across different devices and applications. Manufacturers and developers may employ their own proprietary algorithms and techniques to optimize the speech-to-noise separation process."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "By employing dual sound processors to enhance speech and reduce background noise, audio devices can provide users with clearer, more intelligible speech signals and a more natural auditory experience in various listening situations."), "\n", React.createElement("video", {
    className: "o-fluid-img",
    src: "https://res.cloudinary.com/dhqvlsb3l/video/upload/f_auto,q_auto:best/v1/assets/na/seo-website/video/go-reveal.mp4",
    poster: "https://res.cloudinary.com/dhqvlsb3l/video/upload/f_auto,q_auto:best/v1/assets/na/seo-website/video/go-reveal.jpg",
    autoPlay: true,
    loop: true,
    playsInline: true,
    muted: true
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "horizon-go-ix-benefits-and-features",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#horizon-go-ix-benefits-and-features",
    "aria-label": "horizon go ix benefits and features permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Horizon Go IX benefits and features"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Horizon Go IX uses first-of-its-kind IX platform technology, solving for more complex hearing situations. Using two separate sound processors, IX technology within the Horizon Go IX hearing aid significantly separates speech and background noise. This means you’ll hear every word more clearly than ever while being unphased by pesky background noise. But that’s not all. Here are some of the additional features noted in the Horizon Go IX reviews, which make it a top pick for wearers:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Virtually invisible fit:"), " Tucks discreetly behind your ear, so no one will know you’re wearing it."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Bluetooth Connectivity:"), " Easily connect to stream sound in the car, from the TV, and from your phone no matter where you are with ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "bluetooth connectivity")), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Innovative Speech Focus:"), " Boost speech clarity to a higher level and filter out background noise with a quick touch of a button, so you can focus on the person talking with you — not other people in the room."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Soothing Relax Mode:"), " Peace and quiet never came so easy. Sit back and relax by streaming soothing soundscapes right through your Horizon Go IX hearing aids."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "360-degree Panorama Effect:"), " Whether you’re hiking, hunting, biking, or walking the dog, this 360-degree setting helps you hear everything life has to offer."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Easy-to-use smartphone app:"), " Discreetly control your volume and change and boost settings right from the palm of your hand."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Convenient Rechargeability:"), " With no tiny batteries to change and up to 28 hours of battery life, the Horizon Go IX will keep going all day every day thanks to its rechargeable lithium-ion batteries and mobile charging box. 30-minute quick charge feature that allows to go for 6 more hours."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "IP 68 rating:"), " Ensure the hearing aids will still function after being immersed under 1m of water for 1h."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Own Voice Processing 2.0 (OVP 2.0):"), " Uses real-time recognition of the wearer’s voice to preserve environmental awareness without masking speech."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "CROS & BiCROS available:"), " Compatible with all technologies. Has the lowest system latency in the industry. Provides a smoother sound experience and improves localization."), "\n"), "\n"), "\n", React.createElement(StateCta, {
    backgroundColor: "white"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "choose-your-perfect-match",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#choose-your-perfect-match",
    "aria-label": "choose your perfect match permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Choose your perfect match"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Horizon Go IX hearing aids come in four neutral colors of beige, black, silver and sandy brown for easy blending if desired. But, because it’s slim and tucks nicely behind your ear, it will be hard for anyone to notice you’re wearing it."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "is-the-horizon-go-ix-right-for-you",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#is-the-horizon-go-ix-right-for-you",
    "aria-label": "is the horizon go ix right for you permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Is the Horizon Go IX right for you?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Getting the right hearing aid is crucial to your well-being. That’s why we’re here. Horizon Go IX meets your most active needs and delivers awesome performance in any situation. If you have mild to severe hearing loss and are looking for a high-performance hearing that can keep up with you all day, ask us about trying the Horizon Go IX hearing aid in your everyday life."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, " "), "\n", React.createElement("div", {
    className: "js-trustpilot-widget",
    "data-locale": "en-US",
    "data-template-id": "53aa8912dec7e10d38f59f36",
    "data-businessunit-id": "5601900f0000ff00058398fb",
    "data-style-height": "240px",
    "data-style-width": "100%",
    "data-theme": "light",
    "data-tags": "Go",
    "data-stars": "4,5",
    "data-review-languages": "en"
  }, React.createElement("a", {
    href: "https://www.trustpilot.com/review/hear.com",
    target: "_blank",
    rel: "noopener"
  }, "Trustpilot")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "why-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-hearcom",
    "aria-label": "why hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why hear.com"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Our history")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hundreds of thousands of people have trusted hear.com with their hearing needs since 2012. It’s always been our mission to make quality hearing care more accessible and help people better understand and act on their hearing loss in order to improve their quality of life."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/about-us-timeline-desktop.png",
    alt: "hear.com story timeline",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "What makes us different from the rest")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Your hearing is unique. That’s why we offer personalized care for your specific needs from the very first phone call. After millions of customer consultations, we knew we had to develop a formula for success to make sure those with hearing loss had the right support and care when investing in better hearing and a better life. Only when best-in-class hearing aids are paired with top professional care can the best outcomes be guaranteed."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our ", React.createElement("a", {
    href: "https://www.hear.com/about-us/hearing-success-program/"
  }, "Hearing Success Program™"), " is the only one in the industry that guides and supports you throughout and after your trial. From day one, we’ll be with you each step of the way and anytime you need us. We know you’ll join our other happy customers in saying, “I wish I had gotten these hearing aids sooner!”"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "expert-hearing-care-near-you",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#expert-hearing-care-near-you",
    "aria-label": "expert hearing care near you permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Expert hearing care near you"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You shouldn’t have to go far for quality hearing care. Our expert audiologists and hearing experts are conveniently located nearby, right in your own neighborhood. It is our promise that you’ll only work with the top 2% of hearing professionals in the country who can guarantee your best result. Keep in mind that the Horizon Go IX hearing aids must be programmed by a professional to make a real difference in your life. We can’t wait to meet you!"), "\n", React.createElement(LandingPageCta, {
    copy: "Try Horizon Go IX hearing aids now",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Hearing Loss",
    ContentCopy2: "There are several types of hearing loss and many different cause.",
    Url2: "/hearing-loss/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/resources/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
